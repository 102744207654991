import React from "react"

const reducer = (state, action) => {
    switch (action.type) {
        case "change_language":
            return {
                ...state,
                language: action.language
            }
        case "toggle_is_high_contrast":
            return {
                ...state,
                isHighContrast: !state.isHighContrast
            }
        case "toggle_is_big_font":
            return {
                ...state,
                isBigFont: !state.isBigFont
            }
        case "set_is_high_contrast":
            return {
                ...state,
                isHighContrast: action.isHighContrast
            }
        case "set_is_big_font":
            return {
                ...state,
                isBigFont: action.isBigFont
            }
        default:
            return state
    }
}

const initialState = {
    language: "german",
    isHighContrast: false,
    isBigFont: false
}

const SettingsContext = React.createContext(
    [initialState, () => null]
)

const SettingsProvider = ({ children }) => {
    const [settings, dispatchSettingsAction] = React.useReducer(reducer, initialState)

    return (
        <SettingsContext.Provider value={[settings, dispatchSettingsAction]}>
            { children }
        </SettingsContext.Provider>
    )
}

const actions = {
    changeLanguage: (language) => { return { type: "change_language", language } },
    toggleIsHighContrast: () => { return { type: "toggle_is_high_contrast" } },
    toggleIsBigFont: () => { return { type: "toggle_is_big_font" } },
    setIsHighContrast: (isHighContrast) => { return { type: "set_is_high_contrast", isHighContrast } },
    setIsBigFont: (isBigFont) => { return { type: "set_is_big_font", isBigFont } },
    
}

export { reducer, initialState, SettingsContext, SettingsProvider, actions }